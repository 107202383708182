import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';

import ReactPlayer from 'react-player';
import AWS from 'aws-sdk';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import path from 'path-browserify'
import WaveSurfer from "wavesurfer.js";
import { set } from 'lodash';
import './VideoComponent.css'
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Replay5Icon from '@mui/icons-material/Replay5';
import Forward5Icon from '@mui/icons-material/Forward5';
import Begin from '@mui/icons-material/FirstPage';
import $ from 'jquery';

let videoReady = false;
let audioReady = false;

const url = window.location.href;


let width = 500;
let top = 250;
let left = 110;
let height = 110;
let disabledDrag = false;
let showModal = false;
let portalWindow = false
let showWaveformBase = false
let debounceTimer
let rememberData 

if(url.endsWith('AudioMateVPlayer')){

  width = '100%';
  top = 0;
  left = 0;
  disabledDrag = true;
  showModal = true
  portalWindow = true
  showWaveformBase = true
}  


export const VideoComponent = forwardRef((props, ref) => {
  
  const playerRef = useRef(null);
  const cardRef = useRef(null);
  
  const [currentCursorPosition, setCurrentCursorPosition] = useState('---');
  const [totalLength, setTotalLength] = useState('---');
  const paramsS3 = useRef({})

  const [videoSettings, setVideoSettings] = useState({
    partialVideoUrl: '',
    videoUrl: '',
    showModal: showModal,
    width: width,
    showWaveform: showWaveformBase,
    loadingWaveform: true,
    inplay: false,
    filename: '',
  })
  
    useEffect(() => {
      if(portalWindow){
        window.addEventListener('load', handleCompleteLoad);
        window.addEventListener('message', handleMessage);
      }
    return () => {
      if(portalWindow){
        window.removeEventListener('message', handleMessage);
        window.removeEventListener('load', handleCompleteLoad);
      }
  
    }
  }, [])


  const handleCompleteLoad = () => {};

  const handleMessage = (props) => {
    if(props.data.playInfo){

      rememberData = {
        filename: props.filename,
        url: props.url
      }
      setVideoSettings(previous => ({
        ...previous,
        partialVideoUrl: props.data.playInfo.url,
        filename: props.data.playInfo.filename,
      }))
    }
    if(props.data.shortCut){
      if(props.data.shortCut === 'playPause'){
        pausePlay();
      }
      if(props.data.shortCut === 'ArrowLeft'){
        goback5Seconds()
      }

      if(props.data.shortCut === 'ArrowRight'){
        goforward5Seconds()  
      }
    }
    
  }
  
  const wavesurferRef = useRef(null);

    /* handle audio ready */
    const handleAudioLoaded = useCallback(() => {
      setVideoSettings(previous => ({
        ...previous,
        loadingWaveform: false
      }))
      audioReady = true;
      playBoth()
      // Codice da eseguire quando l'audio è stato caricato in WaveSurfer.js
    },[]);

    window.addEventListener('resize', resizePortalWindow);

    /* general useEffect */
    useEffect(() => {
      window.addEventListener('resize', resizePortalWindow);
      window.addEventListener('beforeunload', () => {
        interactionWithScript({action: {type: 'destroy'}})
      });

      return () => {
        window.removeEventListener('resize', resizePortalWindow);
      }
  }, []);


  /* USE EFFECT FOR WAVESURFER*/
  useEffect(() => {

    if(videoSettings.showModal){
      wavesurferRef.current = WaveSurfer.create({
        container: '#videowaveform', // L'elemento HTML in cui verrà visualizzata la forma d'onda
        waveColor: '#3498db',
        progressColor: '#999',
        height: 60,
        width: '100%',
        fillParent: true,
        splitChannels: true,
        volume: 0
      });
      videoReady = false;
      audioReady = false;

      return () => {
        wavesurferRef.current.destroy(); // Assicurati di distruggere l'istanza quando il componente viene smontato
      };
  }
  }, [videoSettings.partialVideoUrl, videoSettings.showModal]);

  

  function resetUrl(){
    paramsS3.current = {}
  }

  function refreshWave(){
    wavesurferRef.current.load(playerRef.current.getInternalPlayer());
  } 


  useEffect(() => {

    setVideoSettings(previous => ({
      ...previous,
      loadingWaveform: true
    }))

    const s3 = new AWS.S3();  
    // Set the expiration time for the signed URL (in seconds)
    const expiration = 300; // Replace with your desired expiration time

    const baseKey = videoSettings.partialVideoUrl;
    const optimizedKey = videoSettings.partialVideoUrl.slice(0, -4) + 'o.mp4';

    if(videoSettings.partialVideoUrl){

      setCurrentCursorPosition('---');
      setTotalLength('---');

      s3.headObject({ Bucket: 'audiomate', Key: 'projects-audiomate/' + optimizedKey }, (err, data) => {

        videoSettings.partialVideoUrl = baseKey

        if(!err && data){//if optimized doesn't exists
          videoSettings.partialVideoUrl = optimizedKey
        }

        paramsS3.current = {
          Bucket: 'audiomate',
          Key: 'projects-audiomate/' + videoSettings.partialVideoUrl,
          Expires: expiration,
        }

        s3.getSignedUrl('getObject', paramsS3.current, (err, url) => {
          if (err) {
              console.error('Error generating signed URL:', err);
          } else {
            setVideoSettings(previous => ({
              ...previous,
              videoUrl: url,
              showModal: true,
            }))
          }

          //wavesurferRef.current.load(url);
          wavesurferRef.current.on('ready', function(){
            var isStereo = wavesurferRef.current.backend.buffer.numberOfChannels > 1;

            if (isStereo) {
                // If stereo, reduce the height
                wavesurferRef.current.params.height = 30;
            } else {
                // If mono, increase height (or keep default)
                wavesurferRef.current.params.height = 60;
            }       
            // Render the waveform with the new height
            wavesurferRef.current.drawBuffer();

            handleAudioLoaded()
          })

          wavesurferRef.current.setVolume(0);
          wavesurferRef.current.on('seek', handleInteraction);
          wavesurferRef.current.on('audioprocess', () => {
            const currentPosition = wavesurferRef.current.getCurrentTime();
            setCurrentCursorPosition(formatTime(currentPosition));
            setVideoSettings(previous => ({
              ...previous,
              inplay: true
            }))
          });
        });
      }
    )}
  //}, [videoSettings.partialVideoUrl, handleAudioLoaded]);
  } , [videoSettings.partialVideoUrl]);

  useEffect(() => {

    window.addEventListener('keydown', playerKeyDown);
    
    return () => {
      window.removeEventListener('keydown', playerKeyDown);
    }

  }, [])

  function playerKeyDown(props){
    try{
      if(props.shiftKey){
        if(props.key === ' '){
          pausePlay()  
        }
        if(props.key === 'ArrowLeft'){
          goback5Seconds()
        }
        if(props.key === 'ArrowRight'){
          goforward5Seconds()  
        }
      }
    }
    catch(err){}

  }
        
  useImperativeHandle(ref, () => ({
    playVideo(props) {

      rememberData = {
        filename: props.filename,
        url: props.url
      }

      setVideoSettings(previous => ({
        ...previous,
        partialVideoUrl: props.url,
        showModal: true,
        filename: props.filename,
      }))

    }
  }));

  

  function resizePortalWindow(event) {
    clearTimeout(debounceTimer); // Cancella il timer esistente per prevenire l'esecuzione precedente
    debounceTimer = setTimeout(() => {
      if ($('.videoplayerContainer').is('.portal')) {


        const modalHeight = $(document).height()
        const videoContainer = $('#videoPlayerPlace')
        const startVideoHeight = videoContainer.offset().top;

        const resultHeight = modalHeight - startVideoHeight - 20;

        videoContainer.height(resultHeight)

        resizeWatermark();
        wavesurferRef.current.drawBuffer();
      }
    }, 500); // Imposta un nuovo timer
  }
  

  /* player config */
  const playerConfig = {
    file: {
      attributes: {
        onContextMenu: e => e.preventDefault(), // disable right click
        controlsList: 'nodownload nofullscreen',// disable download
        disablePictureInPicture: true // disable PictureInPicture mode
      }
    }
  } 

  const interactionWithScript = (props) => {
    if($('.videoplayerContainer').is('.portal')){
        window.postMessage(props, '*');
    }
}

  /* close the UI */   
  function closeVideoUI(){
    const translate = cardRef.current.style.transform;
    let offsetX = 0;
    let offsetY = 0;
    if(translate) {
      const offsets = translate.split('(')[1].split(')')[0].split(',');
      offsetX = parseFloat(offsets[0]);
      offsetY = parseFloat(offsets[1]);  
    }
    left += offsetX;
    top += offsetY;
    setVideoSettings(previous => ({
      videoUrl: '',
      partialVideoUrl: '',
      showModal: false,
      width: width,
      filename: '',
    })) 
    resetUrl();
  }

  /* handle video ready */
  const handleVideoReady = () => {
    videoReady = true;
    resizeWatermark()
    playBoth()
    // Codice da eseguire quando il video è pronto per il play
  };

  /* play once all the instances are ready */
  const playBoth = () => {
    if(videoReady && audioReady){
      setTotalLength(formatTime(wavesurferRef.current.getDuration()))
      wavesurferRef.current.play(playerRef.current.getCurrentTime())
      if(!playerRef.current.player.isPlaying){ wavesurferRef.current.pause() }
    } else if(videoReady) {
      playerRef.current.getInternalPlayer().play()
    }
  }

  /* play */
  function onPlayHandler(props){
    setVideoSettings(previous => ({
      ...previous,
      inplay: true
    }))
    
      wavesurferRef.current.play()
  }

  /* pause */
  function onPauseHandler(){
    setVideoSettings(previous => ({
      ...previous,
      inplay: false
    }))
      wavesurferRef.current.pause()
  }

  /* seek */
  function handleInteraction(e){
    playerRef.current.seekTo(wavesurferRef.current.getCurrentTime())
    if (wavesurferRef.current.isPlaying()) {
      wavesurferRef.current.play();
    }       
  }

  /* seek from video player */
  function handleVideoProgress(progress){
    /* change position to wavesurfer without playing */
    wavesurferRef.current.play(progress)
    if(!playerRef.current.paused){
      playerRef.current.getInternalPlayer().play()
    } else {
      playerRef.current.stop()
    }
  }

  /* go to start */
  const gotoStart = () => {
    wavesurferRef.current.seekTo(0)
    playerRef.current.seekTo(0)
  }
  
  /* go back 5 seconds */
  const goback5Seconds = () => {
    const newTime = playerRef.current.getCurrentTime() - 5;
    playerRef.current.seekTo(newTime, 'seconds')
    //playerRef.current.seekTo(wavesurferRef.current.getCurrentTime() - 5)
    //wavesurferRef.current.skipBackward(5)
  }

    /* go forward 5 seconds */
    const goforward5Seconds = () => {
      const newTime = playerRef.current.getCurrentTime() + 5;
      playerRef.current.seekTo(newTime, 'seconds')
      //wavesurferRef.current.skipForward(5)
    }

  /* play control */
  const playControl = () => {
    setVideoSettings(previous => ({
      ...previous,
      inplay: true
    }))
    wavesurferRef.current.play()
    playerRef.current.getInternalPlayer().play()
  }
  
  /* pause */
  const pauseControl = () => {
    setVideoSettings(previous => ({
      ...previous,
      inplay: false
    }))
    wavesurferRef.current.pause()
    playerRef.current.getInternalPlayer().pause()
  }

  

  const pausePlay = () => {
    if($('.pauseB').is('.btn-outline-danger')){
      pauseControl()
    } else {
      playControl()
    }
  }
 
  /* draw the waveform */


  /* resize the window */
  const onResize = (event, {node, size, handle}) => {

    if($('.videoplayerContainer').is('.portal')){
      event.preventDefault();
      //return 
    }

    /*resizeWatermark()*/
    width = size.width;

    setVideoSettings(previous => ({
      ...previous,
      width: width,
    }))
    try{
      wavesurferRef.current.drawBuffer();
    } catch(e){
      //console.log(e)
    }
  };

  const resizeWatermark = () => {
    const element = document.getElementById('videoPlayerPlace');
    //const video = document.getElementById('videoPlayerPlace');

    const height = element.clientHeight;
    const element2 = document.getElementById('videoWatemark');
    element2.style.height = height - 70 + 'px';

    let watemarkText = 'Keywordsstudios.com'
    for(let i = 0; i < 100; i++){
      watemarkText = watemarkText + ' Keywordsstudios.com'
    }


    updateWatermark(watemarkText, height)
  }

  function updateWatermark(newText, heigth) {
    document.documentElement.style.setProperty('--watermark-text-size', `${heigth / 10}px`);
    document.documentElement.style.setProperty('--watermark-text', `"${newText}"`);
  }

  const cardStyle = {
    width: videoSettings.width, 
    cursor: 'default', 
    position: 'fixed', 
    top: top, 
    left: left,
    zIndex: '10000',
  }

  const showWaveform = () => {
    setVideoSettings(previous => ({
      ...previous,
      showWaveform: !previous.showWaveform
    }))


    resizePortalWindow()
  }

  function changePositionPlayer(){
        closeVideoUI()
        props.onDetachState(rememberData)
    }




    
    



  
    return (
      videoSettings.showModal ? (
        <Draggable disabled={disabledDrag} handle="#draggableVideo">
          <Resizable 
            width={isNaN(videoSettings.width) ? null : videoSettings.width}
            height={100}
            onResize={onResize}
            minConstraints={[390, 0]}
            handle={<div className="moveIconResize"><i className="icon icon-link"></i></div> } 
      >
          <div ref={cardRef} className="videoplayerContainer" style={cardStyle} >
            <div className="card videoplayer" style={{width: videoSettings.width}}>
              <div id="draggableVideo">
                <button className="btn detatableIcon" onClick={changePositionPlayer} title="Detach video player"><i className="bi bi-back"></i></button>
                <button type="button" className="crossClose btn-close closeUI" onClick={closeVideoUI}></button>
                <h6 className="card-title">Video Player: {videoSettings.filename}</h6>
              </div>
              <div id="audioPlayerPlace" style={videoSettings.showWaveform ? {display: 'block'} : {display: 'none'}}>
                {videoSettings.loadingWaveform &&
                  <div id="coverLoading">
                    <div>Loading...</div>
                  </div>
                }
                <div id="videowaveform"></div>
                <span>Cursor: {currentCursorPosition}</span>
                <span style={{float: 'right'}}>Duration: {totalLength}</span>
              </div>

              <div className="controls">
                <div className="btn-group mr-2 btn-group-sm playersControls" role="group">
                  <button 
                    className="btn btn-outline-secondary btn-sm"
                    onClick={gotoStart}
                    title="Rewind"
                    ><Begin /></button>
                  <button 
                    className="btn btn-outline-secondary btn-sm"
                    onClick={goback5Seconds}
                    title="Go back 5 seconds (Shift + Left Arrow)"
                    ><Replay5Icon /></button>
                  <button 
                    className={videoSettings.inplay ? "btn btn-primary btn-sm" : "btn btn-outline-primary btn-sm"}
                    onClick={playControl}
                    title="Play (Shift + Space)"
                    ><PlayArrowIcon /></button>
                  <button 
                    className={!videoSettings.inplay ? "btn btn-danger pauseB btn-sm" : "btn btn-outline-danger pauseB btn-sm"}
                    onClick={pauseControl}
                    title="Pause (Shift + Space)"
                    ><PauseIcon /></button>
                  <button 
                    className="btn btn-outline-secondary btn-sm"
                    onClick={goforward5Seconds}
                    title="Go forward 5 seconds (Shift + Right Arrow)"
                    ><Forward5Icon /></button>
                  {/*<button 
                    className="btn btn-outline-secondary btn-sm"
                    onClick={showWaveform}
                    title="Show Audio Waveform">
                      <i className="bi bi-soundwave" style={{position: 'relative', fontSize: '18px', top: '2px'}}></i>
                    </button>*/}
                    
                </div>
              </div>
              
              <div id="videoPlayerPlace">
              <div id="videoWatemark"></div>
                <ReactPlayer 
                  ref={playerRef} 
                  url={videoSettings.videoUrl}
                  onReady={handleVideoReady}
                  controls={true}
                  config={playerConfig}
                  width='100%' height='100%'
                  onPlay={onPlayHandler}
                  onPause={onPauseHandler}
                  onSeek={(progress) => handleVideoProgress(progress)}
                  />
                
              </div>

            </div>  
          </div>
          </Resizable>
        </Draggable>
      )
      : null
    )
})

// how to implement the video component in another component 
/*
  1. import the component

      import {VideoComponent} from './VideoComponent';

  2. create a ref

      const player = useRef(null);

  3. add the VideoComponent to the component
  
        <VideoComponent ref={player} />

  4. call the function playVideo with the S3 url of the video

      function playVideo(partialVideoUrl){
        player.current.playVideo(partialVideoUrl)
      }
*/

/* function to convert seconds to HH:MM:SS:ms */

function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  const milliseconds = Math.floor((seconds - Math.floor(seconds)) * 1000);

  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours.toString().padStart(2, '0')}:`;
  }

  formattedTime += `${minutes.toString().padStart(2, '0')}:`;
  formattedTime += `${remainingSeconds.toString().padStart(2, '0')}`;
  
  if (milliseconds > 0) {
    formattedTime += `.${milliseconds.toString().padStart(3, '0')}`;
  }

  return formattedTime;
}

export const visibleVPlayer = () => {
  $('.videoplayerContainer').addClass('portal');
  window.name = 'AudioMate Video Player';
  document.title = window.name;
}
