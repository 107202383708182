import {useParams, useNavigate, Link } from 'react-router-dom';
import { useEffect } from 'react';
import {LoadingBar} from '../../ApiCenter/API_GET';

export const ProjectBreakdownfakeLink = () => {



    const navigate = useNavigate();  
    const idProject = useParams();

    const currPath = idProject.id.split("-")

    // removeDuplcated values in array
    const removeDuplicated = (array) => {
        return array.filter((a, b) => array.indexOf(a) === b)
    }
    useEffect(() => {

        setTimeout(() => {
            navigate(`/projectsdetails/${removeDuplicated(currPath).join('-')}/breakdown`, { replace: true });
        }, 1000);
    }, []);


    


    return <main id="main" className="main"><LoadingBar /></main>
}