import React, {useState, useRef  } from 'react';
import {VideoComponent} from './VideoComponent';


var tempo
const VideoPlayer = (props) => {

  const player = useRef(null);

  const [playerPosition, setPlayerPosition] = useState('right')

  function playVideo(partialVideoUrl){
    player.current.playVideo(partialVideoUrl)
  }

  const videoPlayer = <VideoComponent ref={player} />


  function move(direction){

    if(direction === 'left'){
      setPlayerPosition(previous => 'left')
    }else{
      setPlayerPosition(previous => 'right')
    }
  }





  
  return (
    <main id="main" className="main">
        <button onClick={() => playVideo({url: '##_BackupAppFrontEnd/01.mp4', filename: 'ciao'})}>Video 1</button>
        <button onClick={() => playVideo('##_BackupAppFrontEnd/Video2.mp4')}>Video 2</button>

        <button onClick={() => move('left')}>Left</button>
        <button onClick={() => move('right')}>Right</button>

        <div className="container">
          <div className="row">
            <div className="col-sm-6" style={{float: 'right'}}>
              {videoPlayer}
            </div>
            <div className="col-sm-6" style={{float: 'left'}}>
              CIAO
              </div>
          </div>
        </div>
    </main>
  );
};

export default VideoPlayer;
