import { useEffect, useState } from "react"
import { parseUsername, API_ADR } from '../../ApiCenter/API_GET';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const ProfileSettings = () => {

  const [currentDataUser, setCurrentDataUser] = useState({})
  const [applyDisAppSettings, setApplyDisAppSettings] = useState(true)

    useEffect(() => {
      setCurrentDataUser(parseUsername())    
    }, [])

    
    const onChangeAppSettings = (e) => {
      setCurrentDataUser(previous => ({ ...previous, [e.target.name]: e.target.value }))
      setApplyDisAppSettings(previous => false)
    }

    const applyAppSettings = async () => {

      setApplyDisAppSettings(previous => true)

      const headers = {
        'Content-Type': 'text/plain'
      };


      // eslint-disable-next-line
      const result = await axios.post(
        API_ADR(`changeTimeFormat=1`),
        currentDataUser,
        {headers}
      ).then(function (result) {
        toast.success('Settings Applied!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      })

      localStorage.setItem('user', JSON.stringify(currentDataUser))
  
    }
    
    return (
      <main className="main" id="main">
        <ToastContainer />
          <div className="pagetitle">
              <h1>Account Settings</h1>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-start mt-3">
                <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{width: '200px'}}>
                  <button className="nav-link active" id="v-pills-userinfo-tab" data-bs-toggle="pill" data-bs-target="#v-pills-userinfo" type="button" role="tab" aria-controls="v-pills-userinfo" aria-selected="true">Personal data</button>
                  <button className="nav-link" id="v-pills-appsettings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-appsettings" type="button" role="tab" aria-controls="v-pills-appsettings" aria-selected="false" tabIndex="-1">Application</button>
                  {/*<button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false" tabindex="-1">Messages</button>*/}
                </div>
                <div className="tab-content" id="v-pills-tabContent" style={{width: '100%'}}>
                  <div className="tab-pane fade show active" id="v-pills-userinfo" role="tabpanel" aria-labelledby="v-pills-userinfo-tab">
                  {/* personal data */}
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Your personal data</h5>
                      <div>Name: {currentDataUser.resourcename}</div>
                      <div>Alias: {currentDataUser.useralias}</div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="v-pills-appsettings" role="tabpanel" aria-labelledby="v-pills-appsettings-tab">
                  {/* personal data */}
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Application Settings</h5>
                      <div className="row mb-3">
                        <label className="col-2 col-form-label">Time Format:</label>
                        <div className="col-2">
                          <select name="timeformat12" className="form-select form-select-sm" value={currentDataUser.timeformat12 || ''} onChange={onChangeAppSettings}>
                            <option value={true}>12h</option>
                            <option value={false}>24h</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label className="col-2 col-form-label">Time or Duration:</label>
                        <div className="col-2">
                          <select name="time_or_duration" className="form-select form-select-sm" value={currentDataUser.time_or_duration || ''} onChange={onChangeAppSettings}>
                            <option value={true}>Duration</option>
                            <option value={false}>Time format</option>
                          </select>
                        </div>
                        <div className="col-4">
                          (i.e. in BreakDown, Scheduler)
                        </div>
                      </div>
                      <div className="mt-6">
                        <button 
                          className="btn btn-primary btn-sm" 
                          style={{width: '90px'}} 
                          disabled={applyDisAppSettings}
                          onClick={applyAppSettings}>Apply</button>
                      </div>
                    </div>
                  </div>                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
  )
}
