import React, { createContext, useRef, useEffect, useCallback } from 'react';
import { API_ADR, API_ADRWS, AM_socketId } from '../../ApiCenter/API_GET';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const _ = require('lodash');
const PronunciationContext = createContext();

const PronunciationsContextProvider = ({ children }) => {
    const actorsWindow = useRef(null);
    /* external windows */
    const extPlayerRef = useRef(null)
    const extPlayerRefCheck = useRef(false);
    const extVPlayerRef = useRef(null)
    const extVPlayerRefCheck = useRef(false);
    const activeLoopFromAudioModal = useRef(null)

    const actorsWindowCheck = useRef(false);
    const selChar = useRef(null);
    const selectedId = useRef(null);
    const unfilteredDataSource = useRef(null);
    const sourceAudioBase = useRef({});
    const dataSourceIndex = useRef({});
    const dataTargetIndex = useRef({});
    const dataSource = useRef(null);
    const liveTask = useRef(null);
    const taskId = useRef(0);
    const userName = useRef(JSON.parse(localStorage.getItem("user")).resourcename);
    const userRoles = useRef([]);
    const audioMateRoles = useRef([]);
    const userPermission = useRef([]);
    const readOnly = useRef(true);
    const breadcrumb = useRef([]);
    const checkEnabled = useRef([]);
    const externalUser = useRef(true);
    const activeroles = useRef([])
    const qaTags = useRef([]);
    const containsContextLines = useRef(false);
    const multiSelection = useRef({});
    //const sharedLinesIds = useRef({});
    const targetColumn = useRef(null);
    const playManual = useRef(null);
    const previousFiletype = useRef(null);
    const scriptPersistence = useRef({ 
        1: {filter: {}, columns: {}, selectedId: 0, columnsDefault: {}, showNottoadaptLines: 0, aditionalFilters: {}, sidepanelColumns: []}, 
        2: {filter: {}, columns: {}, selectedId: 0, columnsDefault: {}, showNottoadaptLines: 0, aditionalFilters: {}, sidepanelColumns: []},
        3: {filter: {}, columns: {}, selectedId: 0, columnsDefault: {}, showNottoadaptLines: 0, aditionalFilters: {}, sidepanelColumns: []},
        4: {filter: {}, columns: {}, selectedId: 0, columnsDefault: {}, showNottoadaptLines: 0, aditionalFilters: {}, sidepanelColumns: []},
        5: {filter: {}, columns: {}, selectedId: 0, columnsDefault: {}, showNottoadaptLines: 0, aditionalFilters: {}, sidepanelColumns: []},
        6: {filter: {}, columns: {}, selectedId: 0, columnsDefault: {}, showNottoadaptLines: 0, aditionalFilters: {}, sidepanelColumns: []},
        7: {filter: {}, columns: {}, selectedId: 0, columnsDefault: {}, showNottoadaptLines: 0, aditionalFilters: {}, sidepanelColumns: []},
     });
     const undoState = useRef({ 
        1: {undo: {}, redo: {}},
        2: {undo: {}, redo: {}},
        3: {undo: {}, redo: {}},
        4: {undo: {}, redo: {}},
        5: {undo: {}, redo: {}},
        6: {undo: {}, redo: {}},
        7: {undo: {}, redo: {}},
     });
     const sharedLinesOptions = useRef({
        completedPropagation: true,
        incompletedPropagation: false,
        textPropagation: true,
        remember: false,
     })

    const qaStatusTags = useRef([]);
    const qaTagsGroups = useRef({});
    const qaStatusData = useRef({});  
    const refreshColumn = useRef(null);
    const refreshRow = useRef(null);
    const refreshAll = useRef(null);
    //const checkSharedLines = useRef(null);
    const highlightChanges = useRef(false);
    const intervalId = useRef(null);
    const updateAlert = useRef(null);
    const originalData = useRef([])
    const glossary = useRef([]);
    const glossaryMapping = useRef([]);
    const scriptIds = useRef(null);
    const tcColors = useRef(null);
    const rolesData = useRef([]);
    const extraColumnsHeaders = useRef({});
    const forceUnlock = useRef({});
    const targetFiletypes = useRef({});
    const tasksData = useRef(null);
    const sidePanelsColumns = useRef([]);
    const sharedLinesExist = useRef(false);
    const lastUpdate = useRef(
        {
            pmUpdate: null, //
            scriptTarget: null,
            scriptSource: null,
            actors: null,
            sourceAudio: null,
            pronunciations: null,
            unlockScript: null,
            scriptSettings: null, //Not needed
            targetFiletypes: null
        }
    );
    const localTargetUpdates = useRef({});
    const localSourceUpdates = useRef({});
    const localSharedUpdates = useRef({});
    const updating = useRef(false);
    const progBar = useRef(null);
    const progBarUpdate = useRef(null);
    const updateSidePanel = useRef(null);
    const setPronGridDatasource = useRef(null);
    const refreshActorScript = useRef(null);
    const temporalDatasource = useRef([]);
    const refreshPMUpdates = useRef(null);
    const pmUpdatePending = useRef(0);
    const updateCounter = useRef(null);
    const scriptLinesLimit = useRef(6000);
    const scriptSettings = useRef({});
    const socket = useRef(null);

    const setLastUpdate = (date, types) => {
        if(!types) { types = lastUpdate.current }
        Object.keys(types).forEach((k) => { lastUpdate.current[k] = date });
    }

    const updateProgressBar = (value, label, barNum) => {
        const bar = barNum === 1 ? progBar.current : progBarUpdate.current
        if (bar) { bar.updateProgress(value, label) };
    }

    const checkDeactivated = (r) => {
        // 0 = No activable, 1 = Deactivated, 2 = Activated, 
        if(r.activated_line === '0' && !scriptSettings.current[r.script_language_id].deactivableLine) { return 0 } 
        if(r.activated_line === '1' || (scriptSettings.current[r.script_language_id].deactivableLine && r.deactivated_line === '1')) { return 1 } 
        if(r.activated_line === '2' || (scriptSettings.current[r.script_language_id].deactivableLine && r.deactivated_line === '0')) { return 2 } 
    }

    const editPermissions = (field, myId, editable, prevDisabled) => {
        
        if(readOnly.current) { return 1 }
        //0 = editable, 1 = locked, 2 = readOnly
        const task = parseInt(taskId.current);
        const compareTaskId = task === 7 ? 3 : task;
        const dataRow = unfilteredDataSource.current[myId];

        if(dataRow.only_context_edit === '1' || !editable) { return 2 }
        if(dataRow.only_context_edit === '2' && task < 4) { return 2 }
        if(checkDeactivated(dataRow) === 1) { return 2 }
        if(selChar.current && dataRow.character_name !== selChar.current) { return 1 }
        if(task === 6 && !['qa_status', 'delivery_completed'].includes(field) && lineCompletedCheck(dataRow)) { return 1 }

        const scriptLangId = dataRow.script_language_id;

        const qaStatus = parseInt(dataRow.qa_tag_id);
        const qaStatusLocks = qaStatusData.current.tags[qaStatus].locks;
        const locks = qaStatusLocks.qaStatus?.[task];
        const lockedVal = !locks ? 0 : locks === 1 ? 1 : dataRow.qa_status_log?.at(-1)?.task === task ? 0 : 1;

        if(task !== 6 && lineCompletedCheck(dataRow, 6)) { return 1 }

        if(field === 'qa_status') { return lockedVal }
        if(qaStatus > 0 && field.includes('_completed')) { 
            return prevDisabled && !qaStatusLocks?.prev?.[task] ? 1 : !prevDisabled && qaStatusLocks.completed?.[task] ? 1 : 0; 
        }

        if(qaStatus > 0 && field === 'bug_fixing_text') { return 0 }
        if(qaStatus > 0 && !qaStatusLocks.edit[task]) { return 0 }

        if(forceUnlock.current[scriptLangId] && forceUnlock.current[scriptLangId][task] && forceUnlock.current[scriptLangId][task].includes(field)) { return 0 }

        if(field === liveTask.current + '_notes') { return 0 }
        if(qaStatusLocks.edit[task] === 1) { return 1 }

        if(compareTaskId < 6 && field.includes('_completed') && (dataRow[field] === '0' || !dataRow[field])) { return 0 }

        let completedId = 0;
        completedId = dataRow.precheck_completed === '1' ? 2 : completedId;
        completedId = dataRow.adaptation_completed === '1' ? 3 : completedId;
        completedId = dataRow.ai_completed === '1' ? 3 : completedId;
        completedId = dataRow.recording_completed === '1' ? 4 : completedId;
        completedId = dataRow.postpro_completed === '1' ? 5 : completedId;
        completedId = dataRow.qa_completed === '1' ? 6 : completedId;
        completedId = dataRow.delivery_completed === '1' ? 7 : completedId;

        if((compareTaskId + 1) < completedId) { return 1 }

        /*if(field !== 'delivery_completed' && completedId === 7 && [0,6,7,10,13].includes(qaStatus)) { return 1 }
        if(task < 3 && (task + 1) < completedId) { return 1 }
        if(task === 4 && completedId === 6 && [0,6,9,10,11,13].includes(qaStatus)) { return 1 }
        if(task === 3 && (task + 1) < completedId && [0,3,4,5,6,7,12,13].includes(qaStatus)) { return 1 }
        if(task === 5 && completedId > 4 && [7].includes(qaStatus)) { return 1 }
        if(task === 5 && [8].includes(qaStatus)) { return 0 }*/
        
        completedId = field.includes('_completed') ? completedId - 1 : completedId;

        //if (field === 'bug_fixing_text' || field.includes('_notes')) { return 0 }
        if (compareTaskId === 6 && !['delivery_completed', 'qa_status', 'pm_notes', 'language_notes', 'target_audio'].includes(field)) { return 1 }

        const onlyEdit = dataRow.only_context_edit === '2';
        if ([2,3].includes(compareTaskId) && onlyEdit) { return 2 }

        /*if (field === 'qa_status') { 
            return (task === 1 || !qaStatusTags.current.some((i) => i.tag_type === 'issues' && parseInt(i.id) === qaStatus && i[liveTask.current + '_active'] === '1')) ? 2 : 0;
        }*/

        /*if(field === 'delivery_completed') {
            return dataRow.delivery_completed === '0' && ![0,6,7,10,13].includes(qaStatus) ? 1 : 0;
        }*/

        /*if(task === 3 && qaStatus > 1) {
            return 1
        } else if(task === 4 && [1,4,6,12].includes(qaStatus)) {
            return 1
        } else if(task === 5 && [1,2,3,5,6].includes(qaStatus)) {
            return 1
        } else if(task === 6 && [1,2,3,5].includes(qaStatus)) {
            return 1
        } else if(task === 2 && qaStatus>0) {
            return 1
        }

        if(qaStatus > 0 && field.includes('_completed')) { 
            return parseInt(dataRow[field]);
        }*/

        if(compareTaskId < completedId && field.includes('_completed')) { 
            const taskCompleted = dataRow[field] === '1';
            return !taskCompleted ? 0 : 1 
        } 
        //if(task < completedId && (qaStatus === 0 || task < 3)) { return 1 } 

        //if(task === 3 && qaStatus === 1) { return 0 } 
        //if(task === 3 && qaStatus === 2) { return 1 } 

        //if(task === 4 && [1,2,3,5].includes(qaStatus)) { return 0 } 
        //if(task === 5 && [4,8,12].includes(qaStatus)) { return 0 } 

        if(compareTaskId < completedId) { return 1 }  
        
        if(compareTaskId === 6 && field === 'target_audio') {
            return 1 // task > completedId || [6,7].includes(qaStatus) ? 0 : 1;
        }

        return 0

    }

    const changeQaStatusOnComplete = (myId, accept) => {

        const data = unfilteredDataSource.current[myId];
        let nextId;
        let selectNext = 1;

        if(accept) { 
            nextId = qaStatusData.current.tags[data.qa_tag_id]?.locks?.next?.[taskId.current];
        } else {
            nextId = qaStatusData.current.tags[data.qa_tag_id].locks?.prev?.[taskId.current];
        }

        if(nextId === -1) {
            const prev = data.qa_status_log?.at(-1);
            nextId = prev?.add ? prev.add : data.qa_status_log?.at(-1)?.id;
            selectNext = 0;
        }

        return [isNaN(nextId) ? -1 : nextId, selectNext];

        /*
        if(r.qa_tag_id !== '8' && r.qa_status_log?.length>0) {
            r.qa_status_log.push({id: parseInt(r.qa_tag_id), task: 5})
        } else if(r.qa_tag_id !== '8') {
            r.qa_status_log = [{id: parseInt(r.qa_tag_id), task: 5}]
        };

        /* [newId, selectNext] */
        /*const qaStatus = parseInt(unfilteredDataSource.current[myId].qa_tag_id);
        const previousState = parseInt(unfilteredDataSource.current[myId].previous_qa_tag_id);
        if([13].includes(qaStatus)) { return -1 }
        const task = parseInt(taskId.current);
        if(task === 3 && qaStatus === 1) { return accept ? [2,1] : [-1,0] }
        if(task === 3 && qaStatus === 2) { return accept ? [-1,1] : [1,0] }
        if(task === 3 && qaStatus === 9) { return accept ? [10,1] : [11,0] }
        if(task === 3 && qaStatus === 10) { return accept ? [-1,1] : [9,0] }
        if(task === 3 && qaStatus === 11) { return accept ? [9,0] : [-1,0] }

        if(task === 4 && qaStatus === 2) { return accept ? [12,1] : [-1,0] }
        if(task === 4 && qaStatus === 12) { return accept ? [-1,1] : [2,0] }
        if(task === 4 && [3,5].includes(qaStatus)) { return accept ? [4,1] : [1,0] }
        if(task === 4 && qaStatus === 7) { return accept ? [-1,1] : [5,0] }
        if(task === 4 && qaStatus === 4) { return accept ? [-1,1] : previousState === 2 ? [3,0] : [5,0] }
        if(task === 4 && qaStatus === 1 && previousState > 0) { return accept ? previousState === 2 ? [3,0] : [5,0] : [-1,0] }
        if(task === 4 && qaStatus === 1 && previousState === 0) { return accept ? [-1,1] : [0,0] }

        if(task === 5 && qaStatus === 4) { return accept ? [6,1] : [5,0] }
        if(task === 5 && qaStatus === 12) { return accept ? [6,1] : [3,0] }
        if(task === 5 && qaStatus === 6 && previousState === 2) { return accept ? [-1,1] : [12,0] }
        if(task === 5 && qaStatus === 3 && previousState === 2) { return accept ? [12,0] : [-1,0] }
        if(task === 5 && qaStatus === 6 && previousState === 1) { return accept ? [-1,1] : [4,0] }
        if(task === 5 && qaStatus === 5 && previousState === 1) { return accept ? [4,0] : [-1,0] }
        if(task === 5 && qaStatus === 11) { return accept ? [-1,1] : [3,0] }
        if(task === 5 && qaStatus === 3 && previousState === 3) { return accept ? [11,1] : [-1,0] }
        if(task === 5 && qaStatus === 8 && previousState === 0) { return accept ? [6,0] : [-1,0] }
        if(task === 5 && qaStatus === 8 && previousState === 1) { return accept ? [6,1] : [-1,0] }
        if(task === 5 && qaStatus === 8 && previousState === 2) { return accept ? [6,2] : [-1,0] }

        return [-1,1]*/

    }

    const lineCompletedCheck = useCallback((r, taskToCheck, ignoreDeactivated) => {

        const task = taskToCheck ? taskToCheck : parseInt(taskId.current);
        const taskCode = tasksData.current[task].task_code
        const toIgnore = (!ignoreDeactivated && checkDeactivated(r) === 1)
                        || r.only_context_edit === '1' 
                        || (r.only_context_edit === '2' && [2, 3].includes(task)) 
                        || (!taskToCheck && selChar.current && r.character_name !== selChar.current)
                        || ((!r[taskCode + '_estimation'] || r[taskCode + '_estimation'] === 0) && task < 6);
        
        if(toIgnore) { return true }

        return r[taskCode + '_completed'] === '1' && !qaStatusData.current.tags[r.qa_tag_id].incompleted?.includes(task);

    }, [])

    const setBugFixingText = (r) => {
        const showQaNotes = scriptSettings.current?.[r.script_language_id]?.showNotesInBugFixing === 1;
        const arr = r.bug_fixing_notes ? [...r.bug_fixing_notes] : [];
        if(showQaNotes && r.qa_notes) { arr.unshift({text: r.qa_notes}) }
        return arr.length > 0 ? arr.map(n => n.text).join(' | ') : null;
    }

    /* DOTS UI PROGRESSION */
    const projectTaskProgression2 = useCallback((props) => {

        if(props.only_context_edit === '1') { return }

        const precheckStyle = props.precheck_completed === '0' ? "notcompleted" : "";
        const adaptStyle = props.adaptation_completed === '0' || !props.adaptation_completed ? "notcompleted" : "";
        const adaptNotNeeded = !props.adaptation_estimation && props.adaptation_completed === '1' ? "notneeded" : '';   

        const recStyle = !lineCompletedCheck(props, 3, true) ? "notcompleted" : "";
        const aiStyle = !lineCompletedCheck(props, 7, true) ? "notcompleted" : "";
        const postStyle = !lineCompletedCheck(props, 4, true) ? "notcompleted" : "";
        const qaStyle = !lineCompletedCheck(props, 5, true) ? "notcompleted" : "";
        const delStyle = !lineCompletedCheck(props, 6, true) ? "notcompleted" : "";

    
        if(precheckStyle && adaptStyle && recStyle && postStyle && qaStyle && delStyle) { return }  
    
        return `<div class="scriptTaskCompletedDiv">
                    <div id="precheck" key="precheck" class="scriptTaskCompleted pre ${precheckStyle}"></div>
                    <div id="adaptation" key="adaptation" class="scriptTaskCompleted adapt ${adaptStyle} ${adaptNotNeeded}"></div>
                    <div id="recording" key="recording" class="scriptTaskCompleted recorded ${recStyle}"></div>
                    <div id="ai" key="ai" class="scriptTaskCompleted ai ${aiStyle}"></div>
                    <div id="postpro" key="postpro" class="scriptTaskCompleted post ${postStyle}"></div>
                    <div id="qa" key="qa" class="scriptTaskCompleted qa ${qaStyle}"></div>
                    <div id="delivery" key="delivery" class="scriptTaskCompleted del ${delStyle}"></div>
                </div>`
    },[lineCompletedCheck]);

    const getNowDate = (date) => {

        const now = date ? new Date(date) : new Date();
        const year = now.getUTCFullYear();
        const month = String(now.getUTCMonth() + 1).padStart(2, '0');
        const day = String(now.getUTCDate()).padStart(2, '0');
        const hour = String(now.getUTCHours()).padStart(2, '0');
        const min = String(now.getUTCMinutes()).padStart(2, '0');
        const seg = String(now.getUTCSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hour}:${min}:${seg}`;
        
    };

    const getPronunciationsTable = (scriptLanguageId) => {
        if (scriptLanguageId === '0') { return glossary.current } 
        const glossaryIds = glossaryMapping.current[scriptLanguageId];
        return glossaryIds ? glossary.current.filter(r => glossaryIds.franchise_id.includes(r.franchise_id) && r.language_id === glossaryIds.language_id) : [];
    }

    const searchPronunciationTerms = (id, text) => {
        if (!text || !glossaryMapping.current || !glossaryMapping.current[id] || !glossaryMapping.current[id].regex) { return null };
        text = '●' + text.replace(/🞀[^🞂]*🞂/g, ' ').replace(/[\s\n]/g, '●●') + '●';
        const r = glossaryMapping.current[id].regex;
        const matches = [...new Set(text.toLowerCase().match(r))];
        if (matches.length>0) {
            const finalArray= [];
            matches.forEach (r => { 
                const trimTerm = r.slice(1,-1).replace(/●●/g, ' ');

                let pronunciation = glossary.current.filter((g) => glossaryMapping.current[id].franchise_id.includes(g.franchise_id) 
                                                                && g.language_id === glossaryMapping.current[id].language_id 
                                                                && g.term.toLowerCase() === trimTerm.toLowerCase());
                if (pronunciation.length>1) {
                    let min;
                    let maxPriorityPronun;
                    pronunciation.forEach((t, idx) => {
                       let gId = glossaryMapping.current[id].franchise_id.findIndex(m => m === t.franchise_id);
                       if (idx === 0 && gId>-1) { min = gId; maxPriorityPronun=t } else if (gId<min && gId>-1) { min = gId; ; maxPriorityPronun=t }
                    })
                    pronunciation = maxPriorityPronun;
                } else {
                    pronunciation = pronunciation[0];
                }

                /*const pronunciation = glossary.current.find(g => glossaryMapping.current[id].glossary_id.includes(g.glossary_id) 
                                                                    && g.language_id === glossaryMapping.current[id].language_id 
                                                                    && g.term.toLowerCase() === trimTerm.toLowerCase());*/

                const scapeTerm=pronunciation.term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/ /g, '●●');
                if(!finalArray.some(r => r.id === pronunciation.id)) {
                    finalArray.push({id: pronunciation.id, 
                        scapeTerm: scapeTerm, 
                        term: pronunciation.term,
                        text: pronunciation.term + ': ' + pronunciation.pronunciation, 
                        notes: pronunciation.notes, 
                        tooltip: pronunciation.notes ? pronunciation.pronunciation + '\nNotes: ' + pronunciation.notes : pronunciation.pronunciation,
                        s3_link: pronunciation.s3_link}); 
                }
            });
    
            if (finalArray) { 
                return finalArray; 
            }
        }
        return null
    }

    /*const checkPronunciations = (dataSource) => {
        if (!glossary.current) { return null };
        const newData = [...dataSource];
        let updated = false;
        newData.forEach ((r) => {
            const pronunciations = glossary.current ? searchPronunciationTerms(r.script_language_id, r[targetColumn.current]) : null;
            const pronunciationText = pronunciations ? pronunciations.map((p) => p.text).join('\n') : null;
            const oldValue = r.pronunciations_data ? JSON.stringify(r.pronunciations_data) : null;
            const newValue = pronunciations ? JSON.stringify(pronunciations) : null;
            if (oldValue !== newValue) { 
                newData[r.MyID] = {...r, pronunciations: pronunciationText, pronunciations_data: pronunciations};
                updated = true;
            };
        });
        return updated ? newData : null;
    }*/

    const addPronunciationsToDataSource = useCallback(() => {
        if (!glossary.current) { return false };
        let updated = false;
        unfilteredDataSource.current.forEach ((r) => {
            const pronunciations = glossary.current ? searchPronunciationTerms(r.script_language_id, r[targetColumn.current]) : null;
            const pronunciationText = pronunciations ? pronunciations.map((p) => p.text).join('\n') : null;
            const oldValue = r.pronunciations_data ? JSON.stringify(r.pronunciations_data) : null;
            const newValue = pronunciations ? JSON.stringify(pronunciations) : null;
            if (oldValue !== newValue) { 
                r.pronunciations = pronunciationText; r.pronunciations_data = pronunciations
                updated = true;
            };
        });
        return updated;
    },[])

    const scriptGlossariesIds = (mapping) => {
        const ids = {};
        mapping.forEach((e) => {
            const existingElement = ids[e.script_language_id];
            if (existingElement) {
                existingElement.franchise_id.push(e.franchise_id);
            } else {
                ids[e.script_language_id] = { script_language_id: e.script_language_id, franchise_id: [e.franchise_id], language_id: e.language_id, regex: null };
            }
        } )
        return ids;
    }

    const setGlossaryData = useCallback((glossaryData) => {
        if (glossaryData && glossaryData.glossary) {
            originalData.current = glossaryData
            const idsMapping = scriptGlossariesIds(glossaryData.mapping);
            for (const key in idsMapping) {
                const mp = idsMapping[key];
                const scriptGlossary = glossaryData.glossary.filter((e) => {
                    return mp.franchise_id.includes(e.franchise_id) && e.language_id === mp.language_id;
                });
                if(scriptGlossary.length>0){
                    const scapeTerms = Array.from(scriptGlossary).map(g => g.term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/ /g, '●●')).sort((a, b) => b.length - a.length);
                    mp.regex = new RegExp(`[^a-zA-ZÀ-ÖØ-öø-ÿ0-9А-Яа-я](?:${scapeTerms.join('|')})[^a-zA-ZÀ-ÖØ-öø-ÿ0-9А-Яа-я]`, 'gi')
                } else {
                    mp.regex = null;
                }
            }    
            glossary.current = glossaryData.glossary;   
            glossaryMapping.current = idsMapping;
        } else {
            if(glossaryData) { originalData.current = [] }
            glossary.current = [];
            glossaryMapping.current = [];
        }
    }, [])

    const reloadPronunciations = async (setPronDataSource) => {  
        if (scriptIds.current) {
            const result = await axios.get(API_ADR(`scriptGlossaries=${scriptIds.current}`));
            if (result.data.pronunciations) { 
                if (!_.isEqual(originalData.current, result.data.pronunciations)) {
                    setGlossaryData(result.data.pronunciations);
                    setClearInterval(true); 
                    setPronDataSource();    
                    if(addPronunciationsToDataSource()) { 
                        refreshColumn.current('pronunciations'); 
                        refreshColumn.current('target_asrec'); 
                        refreshColumn.current('target_adapted'); 
                        refreshColumn.current('target_text'); 
                    }
                }
            };
        }                           
    };

    const fetchPronunciations = useCallback(async ()  => {  
        if (scriptIds.current) {
            const result = await axios.get(API_ADR(`scriptGlossaries=${scriptIds.current}`));
            if (result.data.pronunciations) { 
                if (!_.isEqual(originalData.current, result.data.pronunciations)) {
                    setGlossaryData(result.data.pronunciations);
                    if(addPronunciationsToDataSource()) { 
                        refreshColumn.current('pronunciations'); 
                        refreshColumn.current('target_asrec'); 
                        refreshColumn.current('target_adapted'); 
                        refreshColumn.current('target_text'); 
                    }
                    /*if (updateAlert.current) { 
                        updateAlert.current.style.display = 'initial'
                        toast.info('New pronunciations available!\nPress refresh to update them.', { autoClose: 5000, hideProgressBar: false });
                    };*/
                }
            };
            /*if (Object.keys(sharedLinesIds.current).length > 0) { 
                checkSharedLines.current(); 
            }*/
        }                          
    }, [setGlossaryData, addPronunciationsToDataSource]);

    const altsStringFromJson = (a) => {
        if(!a || a.length === 0) { return null }
        const alts = [];
        let count = 1;
        a.forEach((a) => {
            if(a.active === 1) {
                alts.push(count + ') ' + a.alttext)
                count += 1;
            }
        })
        return alts.length > 0 ? alts.join('\n') : null
    }

    const targetFileCheck = (el) => {
        if(!el.target_filetypes || !el.target_audio) { return 'Missing'}
        let textArray = [];
        const filetypesCount = Object.keys(el.target_filetypes).length;
        Object.keys(el.target_filetypes).forEach((e) => {
            if(!el.target_audio['0'] || !Object.keys(el.target_audio['0']).includes(e)) { textArray.push(el.target_filetypes[e]) }           
        })
        if(el.alternatives_json.length > 0) {
            for (let i = 0; i < el.alternatives_json.length; i++) {
                if(el.alternatives_json[i].active === 1 && (!el.target_audio[el.alternatives_json[i].id] || Object.keys(el.target_audio[el.alternatives_json[i].id]).length < filetypesCount)) {
                    textArray.push('Alts');
                    break
                }
            }
        }
        return textArray.length > 0 ? "Missing " + textArray.join('/') : null;
    }

    const completedUpdate = useCallback((row, task, taskId, skipStatus) => {

        let val = '';
        if(row.only_context_edit === '1') { 
            val = '(Context)' 
        } else {
            const status = row[task + '_completed'] === '1' && !qaStatusData.current.tags[row.qa_tag_id].incompleted?.includes(taskId) ? 'Checked' : 'Not Checked';
            const tag = row.only_context_edit === '2' ? ' (Edit Only)' : '';
            val = status + tag;
        }
        
        row[task + '_completedString'] = val;           
        if(!skipStatus) { row.lineStatus = projectTaskProgression2(row) } 

    },[projectTaskProgression2])

    const addLocalChanges = (ids, type, complete) => {
        if(!updating.current) { return }
        if(type === 0) {
            ids.forEach((i) => localSourceUpdates.current[complete ? i.id : i] = true);
        } else if(type === 1) {
            ids.forEach((i) => {
                const id = !complete ? i : i.id ? i.id : i.script_data_language_id;
                localTargetUpdates.current[id] = true
            });
        } else if(type === 2) {
            ids.forEach((id) => localSharedUpdates.current[id] = true);
        }
    }

    const updateTasksNotes = (row) => {
        let notesArray = [];
        if (row.precheck_notes) {notesArray.push('Pchk: ' + row.precheck_notes)};
        if (row.adaptation_notes) {notesArray.push('Adapt: ' + row.adaptation_notes)};
        if (row.recording_notes) {notesArray.push('Rec: ' + row.recording_notes)};
        if (row.ai_notes) {notesArray.push('Synth: ' + row.ai_notes)};
        if (row.postpro_notes) {notesArray.push('Post: ' + row.postpro_notes)};
        if (row.qa_notes) {notesArray.push('QA: ' + row.qa_notes)};
        row.tasks_notes = notesArray.join('\r\n');
    }
  
    const checkForScriptUpdates = useCallback((fromButton, forPM, typeOfUpdate) => {

        const checkQueu = () => {
            const keys = Object.keys(updateQueu.current);
            if(keys.length === 0) { return }
            const update = {...updateQueu.current};
            updateQueu.current = {};
            keys.forEach((k) => {
                if(update[k] <= lastUpdate.current[k]) {
                    delete update[k];
                }
            })
            if(Object.keys(update).length > 0) {
                checkForScriptUpdates(false, false, update);
            }     
        }

        const createIndexes = (data) => {
            dataTargetIndex.current = {};
            dataSourceIndex.current = {};
            data.forEach((r, idx) => {
                dataTargetIndex.current[r.script_data_language_id] = idx;
                dataSourceIndex.current[r.script_data_id] = idx;
            })
        }

        const finalizePMUpdate = (adapt) => {
            const data = adapt ? unfilteredDataSource.current : temporalDatasource.current
            refreshPMUpdates.current(true, data);
            temporalDatasource.current = [];
            pmUpdatePending.current = 0;
            updating.current = false;
            updateProgressBar(-1, null, 2);
            if(adapt) { 
                checkForScriptUpdates(true); 
            } else {
                checkQueu();
            }
        }

        const refreshPMUpdate = (changeDs) => {
            updating.current = true;
            if(!changeDs && taskId.current === '2') {
                finalizePMUpdate(true);
                return
            } else if(changeDs) {
                createIndexes(temporalDatasource.current);
                checkForScriptUpdates(true, true);
                return
            }
            pmUpdatePending.current = 0;
            if(refreshPMUpdates.current) { refreshPMUpdates.current() }      
            updating.current = false;
            if(updateCounter.current) { updateCounter.current() };
            updateQueu.current = {};
            checkForScriptUpdates(true);        
        }

        if(fromButton && pmUpdatePending.current > 0 && !forPM) {
            updating.current = true;
            refreshPMUpdate(pmUpdatePending.current === 1);
            return
        } else if(pmUpdatePending.current === 1 && !forPM) {
            toast.info('There is an update from the PM for this script.\nPress the refresh button to update the script.', {hideProgressBar: false, position: "top-center"})
            return
        }

        const extensions = ["wav", "aif", "aiff", "flac", "mp3", "ogg", "aac", "mp4", "mov", "avi", 'mkv', "wmv", "wma"];

        const getKeyName = (fileName, removeExt) => {
            if(!fileName) { return }
            if(removeExt) {
                const extensionDot = fileName.lastIndexOf('.');
                if(extensionDot > -1) { 
                    const extension = fileName.split('.').pop().toLowerCase();
                    fileName = !extensions.includes(extension) ? fileName : fileName.slice(0, extensionDot);
                }; 
            }
            return fileName.toLowerCase().replace(/\\/g, '/');
        }

        const addHistoryId = (row, field) => {
            if(!row.history_columns) {
                row.history_columns = field;
            } else if(!row.history_columns.split(",").includes(field)) {
                row.history_columns += ',' + field;  
            }    
        }

        const removeAdaptMarkers = (text) => {
            return text ? text.replace(/🞀[^🞂]+🞂/g, ' ').replace(/\u00A0/g, '').replace(/ {2,}/g, ' ').trim() : null;
        }

        const autoFillJsonKeys = (el) => {
            el.source_precheck_text_wasnull = false
            el.target_adapted_wasnull = false
            el.target_asrec_wasnull = false
    
            if(el.source_precheck_text === null){
                el.source_precheck_text_wasnull = true
                el.source_precheck_text = el.source_text
            }
            if(el.target_adapted === null){
                el.target_adapted_wasnull = true
                el.target_adapted = el.target_text
            }
            if(el.ai_tts === null){
                el.ai_tts = el.target_adapted
            }
            if(el.target_asrec === null){
                el.target_asrec_wasnull = true
                el.target_asrec = el.target_adapted
            }
            if(el.shared_line_id && el.deactivate_shared_line === '0') {
                el['target_adapted_beforeShared'] = el.target_adapted;
                el['target_asrec_beforeShared'] = el.target_asrec ? el.target_asrec : el.target_adapted ? removeAdaptMarkers(el.target_adapted) : null;
            }
            if(el.locked_text !== null && el.shared_line_id && el.deactivate_shared_line === '0') {
                el.target_adapted_wasnull = false
                el.target_asrec_wasnull = false
                el.target_adapted = el.locked_text
                el.target_asrec = removeAdaptMarkers(el.locked_text)
            }
        }

        const addPronunciations = (r) => {
            const pronunciations = glossary.current ? searchPronunciationTerms(r.script_language_id, r[targetColumn.current]) : null;
            const pronunciationText = pronunciations ? pronunciations.map((p) => p.text).join('\n') : null;
            const oldValue = r.pronunciations_data ? JSON.stringify(r.pronunciations_data) : null;
            const newValue = pronunciations ? JSON.stringify(pronunciations) : null;
            if (oldValue !== newValue) { 
                r.pronunciations = pronunciationText; 
                r.pronunciations_data = pronunciations;
                return true;
            };
            return false
        }

        const addSourceFiles = (r) => {
            if(!r.souce_types_id) {
                if(r.source_audio) { r.source_audio = null };
                return
            }
            const filetypesIds = r.souce_types_id.split('|');
            const filetypesNames = r.souce_types_name.split('|');
            const missingFiles = [];  
            if(filetypesIds && filetypesIds.length > 0) {
                filetypesIds.forEach((f, idx) => {
                    const videoKey = r.cinematic_name ? sourceAudioBase.current[f  + '|' + r.script_id + '|' + getKeyName(r.cinematic_name, true)] : undefined;
                    const audioKey = sourceAudioBase.current[f  + '|' +  getKeyName(r.source_link_id)];
                    if(!videoKey && ! audioKey) {
                        missingFiles.push(filetypesNames[idx])
                    }
                })
            }
            const text = missingFiles.length > 0 ? 'Missing ' + missingFiles.join('/') : null;
            r.source_audio = text;
        }

        const fillPronSourceMissing = (updateAudiobase, updatePronunciations, updateActors, forceUnlockUpdated, forPMUpdate, tgFiletypes, targetAudioData, updateScriptSettings) => {

            const destDataSorce = !forPMUpdate ? unfilteredDataSource.current : temporalDatasource.current;

            localSourceUpdates.current = {};
            localTargetUpdates.current = {};
            localSharedUpdates.current = {};

            let updatePronCol = false;
            let updateActorCol = false;
            
            const callback = (data) => {
                data.forEach((r) => {
                    if(updatePronunciations) { updatePronCol = addPronunciations(r) ? true : updatePronCol }
                    if(updateActors && updateActors[r.script_language_id]) { 
                        const actor = updateActors[r.script_language_id][r.character_id];
                        if(actor !== r.actor_name) {
                            r.actor_name = actor;
                            updateActorCol = true;
                        }
                    }
                    if(tgFiletypes?.[r.script_id]) { 
                        r.target_filetypes = tgFiletypes[r.script_id];
                        r.target_audio = targetAudioData?.[r.script_data_language_id]; 
                        r.target_file_check = targetFileCheck(r);  
                    }

                    if(!updateAudiobase) { return }
                    addSourceFiles(r);
                })
            }

            let index = 0;
            const steps = 999;
            const arrayLength = destDataSorce.length;

            const processNext = () => {
                if (index >= arrayLength) {
                    if(forPMUpdate) { 
                        finalizePMUpdate();
                        return 
                    }
                    if(forceUnlockUpdated || updateScriptSettings) { 
                        refreshAll.current(); 
                    } else {
                        if(updatePronCol) {
                            refreshColumn.current('pronunciations'); 
                            refreshColumn.current('target_asrec'); 
                            refreshColumn.current('target_adapted'); 
                            refreshColumn.current('target_text'); 
                        }
                        if(updateAudiobase) { refreshColumn.current('source_audio') }
                        if(updateActorCol) { refreshColumn.current('actor_name') }
                        if(tgFiletypes) { 
                            refreshColumn.current('target_file_check'); 
                            refreshColumn.current('alternatives'); 
                        }
                    }
                    if(updateSidePanel.current) { updateSidePanel.current() }
                    if(refreshActorScript.current) { refreshActorScript.current() };
                    updateProgressBar(-1, null, 2);
                    updating.current = false;
                    checkQueu();
                    return;
                }                                  
                callback(destDataSorce.slice(index, steps + index));
                index += steps + 1;
                if(index>arrayLength) { index = arrayLength }
                setTimeout(processNext, 0);
                updateProgressBar((index/arrayLength)*100, "Updating audiobase", 2);
            }    
            processNext();

        }

        const checkChanges = (updatedData, updateAudioBase, updatePronunciations, updateActors, forceUnlockUpdated, forPMupdate, tgFiletypes, targetAudioData, updateScriptSettings) => {

            const destDataSorce = !forPMupdate ? unfilteredDataSource.current : temporalDatasource.current;

            const callbackFunction = (data) => {
                data.forEach((r) => {
                    const type = r.script_data_id ? 0 : 1;
                    let row = null;
                    let rows = null;
                    if(type === 1) {
                        const id = dataTargetIndex.current[r.script_data_language_id];
                        if(isNaN(id)) { return }
                        row = destDataSorce[id];
                    } else {
                        const ids = dataSourceIndex.current[r.script_data_id];
                        if(!ids) { return }
                        rows = ids.map(idx => destDataSorce[idx]);
                        row = rows[0];
                    }

                    if(row) {

                        let update = false;
                        let updateNotes = false;
                        let updateComplete = false;

                        const ignoreUpdate = type === 0 ? 
                                            localSourceUpdates.current[r.script_data_id] :
                                            localTargetUpdates.current[r.script_data_language_id] || localSharedUpdates.current[r.shared_line_id];
                        if(ignoreUpdate) { return }

                        Object.keys(r).forEach((f) => {

                            let currentVal = row[f];
                            let compareVal = r[f];
                            let newVal = r[f];
                            if(['alternatives_json','ai_tts_settings','bug_fixing_notes','target_audio', 'qa_status_log'].includes(f)){
                                currentVal = JSON.stringify(currentVal);
                                try { 
                                    newVal = newVal ? JSON.parse(newVal) : [];
                                    compareVal = JSON.stringify(newVal); 
                                } catch (error) {}  
                            } if(f === 'target_asrec') {
                                newVal = newVal ? newVal.replace(/🞀[^🞂]+🞂/g, ' ').replace(/\u00A0/g, '').replace(/ {2,}/g, ' ').trim() : null;
                            }                    
                            if(currentVal !== compareVal) {
                                update = true;
                                if(rows) { 
                                    rows.forEach((r) => { 
                                        r[f] = newVal;
                                        addHistoryId(r,f); 
                                    })
                                } else {
                                    row[f] = newVal;
                                    addHistoryId(row,f);
                                }              
                                if(f === 'alternatives_json') { row.alternatives = altsStringFromJson(newVal) }
                                if(f === 'bug_fixing_notes') { row.bug_fixing_text = setBugFixingText(row) }
                                if(f === 'qa_notes') { row.bug_fixing_text = setBugFixingText(row) }
                                if(f === 'target_audio') { row.target_file_check = targetFileCheck(row) }
                                if(f.includes('_completed') || f === 'qa_tag_id') { updateComplete = true }
                                if(f.includes('_notes') && f !== 'language_notes' && f !== 'pm_notes') { updateNotes = true }
                                if(f === 'target_adapted' || (f === 'target_asrec' && (['qa','delivery','postpro'].includes(liveTask.current)))){
                                    if (f === 'target_asrec' || (!['qa','delivery','postpro'].includes(liveTask.current))) {
                                        const pronunciations = searchPronunciationTerms(row.script_language_id, row[f]);
                                        const pronunciationText = pronunciations ? pronunciations.map((p) => p.text).join('\n') : null;
                                        const oldValue = row.pronunciations_data ? JSON.stringify(row.pronunciations_data) : null;
                                        const newValue = pronunciations ? JSON.stringify(pronunciations) : null;
                                        if (oldValue !== newValue) {
                                            row.pronunciations = pronunciationText;
                                            row.pronunciations_data = pronunciations;
                                        }
                                    };
                                }
                                
                            } 

                        })
                        if(updateComplete) { completedUpdate(row, liveTask.current, parseInt(taskId.current)) }
                        if(update && rows) { 
                            rows.forEach((r) => { 
                                if(updateNotes) { updateTasksNotes(r) }
                                if(!forPMupdate) { 
                                    if(!updateScriptSettings) { refreshRow.current(r.MyID) };
                                    if(selectedId.current === r.MyID && updateSidePanel.current) { updateSidePanel.current() } 
                                }
                            }); 
                        } else if(update) {
                            if(updateNotes) { updateTasksNotes(row) }
                            if(!forPMupdate) {
                                if(!updateScriptSettings) { refreshRow.current(row.MyID) };
                                if(selectedId.current === row.MyID && updateSidePanel.current) { updateSidePanel.current() } 
                            }
                        }
                    }            
                })      
            }
  
            let index = 0;
            const steps = 99;
            const arrayLength = updatedData.length;

            const processNext = () => {
                if (index < arrayLength) {
                    callbackFunction(updatedData.slice(index, steps + index));
                    index += steps + 1;
                    if(index>arrayLength) { index = arrayLength }
                    setTimeout(processNext, 0);
                    updateProgressBar((index/arrayLength)*100, "Updating script", 2);
                } else if(updateAudioBase || updatePronunciations || updateActors) {
                    fillPronSourceMissing(updateAudioBase, updatePronunciations, updateActors, forceUnlockUpdated, forPMupdate, tgFiletypes, targetAudioData, updateScriptSettings);
                } else {
                    if(forPMupdate) { 
                        finalizePMUpdate();
                        return 
                    }
                    if(forceUnlockUpdated || updateScriptSettings) { refreshAll.current() }
                    if(refreshActorScript.current) { refreshActorScript.current() };
                    if(updateCounter.current) { updateCounter.current() };
                    updateProgressBar(-1, null, 2);
                    updating.current = false;
                    localSourceUpdates.current = {};
                    localTargetUpdates.current = {};
                    localSharedUpdates.current = {};
                    checkQueu();
                };
            }    
            processNext();

        }

        const sortByFields = (fields) => {
            return (a, b) => {
                for (let field of fields) {
                    if (a[field] < b[field]) {
                        return -1;
                    }
                    if (a[field] > b[field]) {
                        return 1;
                    }
                }
                return 0;
            };
        }

        const finalizeUpdate = (data, sort) => {

            if(sort) {
                data.sort(sortByFields(['project_name', 'batch_name', 'script_name', 'script_order', 'language_code']));
            }

            const sourceFields = ['source_precheck_text', 'fx', 'precheck_notes', 'precheck_completed', 'precheck_date', 'precheck_resource_name', 'pm_notes'];
            const targetFields = [
                'locked_text', 'locked', 'deactivate_shared_line',
                'activated_line', 'deactivated_line', 'target_adapted', 'target_asrec', 'alternatives_json',
                'language_notes', 'adaptation_notes', 'recording_notes', 'ai_notes', 'postpro_notes', 'qa_notes',
                'qa_tag_id', 'qa_tag_severity_id', 'bug_fixing_notes', 'qa_status',
                'adaptation_completed', 'adaptation_date', 'adaptation_resource_name',
                'recording_completed', 'recording_date', 'recording_resource_name',
                'ai_completed', 'ai_date', 'ai_resource_name',
                'postpro_completed', 'postpro_date', 'postpro_resource_name',
                'qa_completed', 'qa_date', 'qa_resource_name',
                'delivery_completed', 'delivery_date', 'delivery_resource_name',
                'ai_tts', 'ai_tts_settings', 'ai_tts_tone_description', 'qa_status_log'
            ];
            const jsonFields = ['alternatives_json', 'bug_fixing_notes', 'target_audio', 'target_filetypes', 'ai_tts_settings', 'pronunciations_data', 'qa_status_log'];
            let contextLines = false;
            const scriptIndex = {};
            const scriptSourceIndex = {};

            const differentCount = data.length !== unfilteredDataSource.current.length;
            let isDifferent = false;
            const toUpdate = [];

            const callback = (dataPart, offset) => {

                dataPart.forEach(function(el, idx){

                    const currentIdx = dataTargetIndex.current[el.script_data_language_id];
                    isDifferent = currentIdx === (idx + offset) ? isDifferent : true;

                    scriptIndex[el.script_data_language_id] = idx + offset;
                    if(!scriptSourceIndex[el.script_data_id]) { scriptSourceIndex[el.script_data_id] = [] }
                    scriptSourceIndex[el.script_data_id].push(idx + offset);
                        
                    contextLines = contextLines ? true : el.only_context_edit !== '0';
                    el.MyID = idx + offset;

                    let jsonAlts = [];
                    try { jsonAlts = el.alternatives ? JSON.parse(el.alternatives) : [] } catch (error) {}          
                    el.alternatives_json = jsonAlts;
                    el.alternatives = altsStringFromJson(el.alternatives_json)

                    try { el.bug_fixing_notes = el.bug_fixing_notes ? JSON.parse(el.bug_fixing_notes) : el.bug_fixing_notes } catch (error) {} ;
                    try { el.bug_fixing_text = setBugFixingText(el) } catch (error) {} ;
                    try { el.target_audio = el.target_audio ? JSON.parse(el.target_audio) : el.target_audio } catch (error) {} ;
                    try { el.target_filetypes = el.target_filetypes ? JSON.parse(el.target_filetypes) : el.target_filetypes } catch (error) {} ;
                    try { el.ai_tts_settings = el.ai_tts_settings ? JSON.parse(el.ai_tts_settings) : null } catch (error) {};
                    try { el.qa_status_log = el.qa_status_log ? JSON.parse(el.qa_status_log) : null } catch (error) {};

                    completedUpdate(el, 1,'precheck', true);
                    completedUpdate(el, 2,'adaptation', true);
                    completedUpdate(el, 3, 'recording', true);
                    completedUpdate(el, 4, 'postpro', true);
                    completedUpdate(el, 5, 'qa', true);
                    completedUpdate(el, 6, 'delivery', false);
                    completedUpdate(el, 7, 'ai', true);
                    el.target_file_check = targetFileCheck(el)

                    autoFillJsonKeys(el)
                    addSourceFiles(el);
                    addPronunciations(el);

                    if(!differentCount && !isDifferent) {
                        const currentRow = unfilteredDataSource.current[currentIdx];
                        Object.keys(el).forEach((f) => {
                            const isJson = jsonFields.includes(f);
                            const oldVal = isJson && currentRow[f] ? JSON.stringify(currentRow[f]) : currentRow[f];
                            const newVal = isJson && el[f] ? JSON.stringify(el[f]) : el[f];
                            if(oldVal !== newVal) { 
                                toUpdate.push({idx: idx + offset, targetIdx: el.script_data_language_id, sourceIdx: el.script_data_id, field: f}) 
                            }
                        })
                    }

                })
            }

            let index = 0;
            const steps = 999;
            const arrayLength = data.length;

            const processNext = () => {
                if (index < arrayLength) {
                    callback(data.slice(index, steps + index), index);
                    index += steps + 1;
                    if(index>arrayLength) { index = arrayLength }
                    setTimeout(processNext, 0);
                    updateProgressBar((index/arrayLength)*100, "Updating audiobase", 2);
                } else {
                    ending()
                } 
            }    
            processNext();

            const ending = () => {

                if(!differentCount && !isDifferent) {
                    const rowsToRefresh = {};
                    toUpdate.forEach((u) => {
                        const ignore = (sourceFields.includes(u.field) && localSourceUpdates.current[u.sourceIdx]) || 
                                        (targetFields.includes(u.field) && localTargetUpdates.current[u.sourceIdx])
                        if(!ignore) {
                            unfilteredDataSource.current[dataTargetIndex.current[u.targetIdx]][u.field] = data[u.idx][u.field];  
                            rowsToRefresh[u.idx] = true;    
                        }
                    })
                    containsContextLines.current = contextLines;
                    Object.keys(rowsToRefresh).forEach((k) => {
                        refreshRow.current(parseInt(k));
                    });    
                    if(toUpdate.length > 0) {
                        if(refreshActorScript.current) { refreshActorScript.current() };
                        if(updateSidePanel.current) { updateSidePanel.current() }
                        pmUpdatePending.current = 2;  
                    } else {
                        pmUpdatePending.current = 0; 
                    }
                } else {
                    temporalDatasource.current = data;
                    pmUpdatePending.current = 1;  
                }  
           
                updateProgressBar(-1, null, 2);
                updating.current = false;
                localSourceUpdates.current = {};
                localTargetUpdates.current = {};
                localSharedUpdates.current = {};

                if(pmUpdatePending.current === 0) { 
                    checkQueu();
                    return; 
                }
    
                if(!fromButton) { 
                    toast.info('There is an update from the PM for this script.\nPress the refresh button to update the script.', {hideProgressBar: false, position: "top-center"}) 
                    if(pmUpdatePending.current === 2) { 
                        checkForScriptUpdates();
                        if(updateCounter.current) { updateCounter.current() };
                    } else {
                        checkQueu();
                    }  
                } else {
                    refreshPMUpdate(pmUpdatePending.current === 1);
                }

            }
            
        }

        const getScriptPart = async(data, slIds) => {

            const idsLeft = slIds.slice(scriptLinesLimit.current, scriptLinesLimit.current * 2);
            const idsToGet = slIds.slice(0, scriptLinesLimit.current);

            const headers = { 'Content-Type': 'text/plain' };
            const dataObj = {scriptLangIds: scriptIds.current, slIds: idsToGet}
            const ObjectToDB = JSON.stringify(dataObj);     
            const link = API_ADR(`getScriptUpdatesPart=1`);
            await axios.post(link, ObjectToDB, {headers}
            ).then(function (response) {
                data = [...data, ...response.data.script];
                if(idsLeft.length > 0) {
                    getScriptPart(data, idsLeft);
                } else {
                    finalizeUpdate(data, true);
                }
            })
        }

        const getUpdates = async(forPMupdate, typeOfUpdate) => {

            if(fromButton) { updateProgressBar(0, 'Checking updates', 2) };
            const headers = { 'Content-Type': 'text/plain' };
            if(!typeOfUpdate) { typeOfUpdate = lastUpdate.current }
            const data = {scriptLangIds: scriptIds.current, typeOfUpdate}
            const ObjectToDB = JSON.stringify(data);     
            const link = API_ADR(`getScriptUpdates=1`);
            updating.current = true;
            localTargetUpdates.current = {};
            localSourceUpdates.current = {};
            localSharedUpdates.current = {};
            const prevUpdate = {...lastUpdate.current};
            await axios.post(link, ObjectToDB, {headers}
            ).then(function (response) {

                setLastUpdate(response.data.last_update, typeOfUpdate)

                let updateScriptSettings = false;
                if(response.data.scriptSettings) {
                    const scriptSettingsParsed = JSON.parse(response.data.scriptSettings.scriptSettings, '{}');
                    if(JSON.stringify(scriptSettingsParsed) !== JSON.stringify(scriptSettings.current)) {
                        scriptSettings.current = scriptSettingsParsed;
                        updateScriptSettings = true;
                    }
                }

                if(response.data.extracolumns) {
                    extraColumnsHeaders.current = JSON.parse(response.data.extracolumns?.[0]?.headers_json ?? '{}');
                }

                if(response.data.targetSettings?.[0]?.filetypes) {
                    const ts = JSON.parse(response.data.targetSettings[0].filetypes);
                    Object.entries(ts)?.forEach(([k, v]) => { 
                        targetFiletypes.current[k] = v 
                    });
                }
                
                let forceUnlockUpdated = false;
                if(response.data.unlockedScripts) {
                    const unlockedScripts = JSON.parse(response.data.unlockedScripts[0].json_obj ?? '{}');
                    if(JSON.stringify(forceUnlock.current) !== JSON.stringify(unlockedScripts)) { 
                        const newLocked = Object.values(unlockedScripts).some((o) => o.hasOwnProperty(taskId.current));
                        const oldLocked = Object.values(forceUnlock.current).some((o) => o.hasOwnProperty(taskId.current));
                        forceUnlock.current = unlockedScripts; 
                        forceUnlockUpdated = oldLocked !== newLocked
                    }
                }

                const updatePronunciations = response.data.pronunciations && !_.isEqual(originalData.current, response.data.pronunciations);
                if (updatePronunciations) { 
                    setGlossaryData(response.data.pronunciations);
                    setPronGridDatasource.current(); 
                }

                const updateAudioBase = response.data.hasOwnProperty('sourceaudio');
                if(updateAudioBase) {
                    sourceAudioBase.current = JSON.parse(response.data?.sourceaudio?.[0]?.audiobase ?? '{}');
                }

                /* It's a PM update */
                if(response.data.script && response.data.slIds) {
                    scriptLinesLimit.current = response.data.limit;
                    getScriptPart(response.data.script, response.data.slIds);
                    return
                } else if(response.data.script) {
                    finalizeUpdate(response.data.script);
                    return
                }

                let tgFiletypes = JSON.parse(response.data?.targetAudio?.shift()?.target_audio ?? null);
                tgFiletypes = tgFiletypes && Object.keys(tgFiletypes).length > 0 ? tgFiletypes : null;
                const targetAudioData = JSON.parse(response.data?.targetAudio?.[0]?.target_audio ?? null);

                const updateActors = JSON.parse(response.data.actors?.[0]?.actors ?? null);
                /* It's not a PM update */
                if(!response.data.target) { response.data.target = [] };
                if(!response.data.source) { response.data.source = [] };
                const joinedData = [...response.data.target, ...response.data.source];
                if(joinedData?.length > 0) { 
                    checkChanges(joinedData, updateAudioBase, updatePronunciations, updateActors, forceUnlockUpdated, forPMupdate, tgFiletypes, targetAudioData, updateScriptSettings);
                } else if(updateAudioBase || updatePronunciations || updateActors || tgFiletypes || targetAudioData) {
                    fillPronSourceMissing(updateAudioBase, updatePronunciations, updateActors, forceUnlockUpdated, forPMupdate, tgFiletypes, targetAudioData, updateScriptSettings);
                } else if(forPMupdate) {
                    finalizePMUpdate();
                } else {
                    if(forceUnlockUpdated || updateScriptSettings) {
                        refreshAll.current();
                    /*} else if(updateScriptSettings) {
                        refreshColumn.current('bug_fixing_text');*/
                    }
                    updateProgressBar(-1, null, 2);
                    updating.current = false;
                    checkQueu();
                }
            }).catch(function (error) {
                console.log("ERROR", error);
                lastUpdate.current = prevUpdate;
                updateProgressBar(-1, null, 2);
                updating.current = false;
                checkQueu();
            }); 
        }
        getUpdates(forPM, typeOfUpdate);    
    },[completedUpdate, setGlossaryData])

    const updateQueu = useRef({});

    const webSocketConnect = useCallback(() => {
        if(socket.current) {
            socket.current.close();
        }
        if(!scriptIds.current) { return }
        socket.current = new WebSocket(API_ADRWS()); // URL del servidor WebSocket  
        //socket.current = new WebSocket(`ws://localhost:8081/chat?token=${token}`); // URL del servidor WebSocket  
        socket.current.onopen = () => {
            console.log('Connection established with WebSocket server');
            socket.current.send(JSON.stringify({scriptLangId: scriptIds.current}));
        };
        socket.current.onmessage = (event) => {
            let message = null;
            try { message = JSON.parse(event.data) } catch (error) { return }
            if(message.updateType === 'resourceId') { 
                AM_socketId(true, message.userId);
                return;
            };
            if(AM_socketId() === message.userId) { return } 
            if(message.lastUpdate < lastUpdate.current[message.updateType]) { return }
            if(updating.current || Object.keys(updateQueu.current).length > 0) {
                console.log(updating.current)
                if (!updateQueu.current.hasOwnProperty(message.updateType)) {
                    updateQueu.current[message.updateType] = message.lastUpdate;
                }           
            } else if(!updating.current) {
                const updateObj = {};
                updateObj[message.updateType] = message.lastUpdate;
                checkForScriptUpdates(false, false, updateObj);
            }             
        }; 
        socket.current.onclose = () => {
            AM_socketId(true, null);
            console.log('Connection closed with WebSocket server');
        };
    }, [checkForScriptUpdates]);

    const setClearInterval = useCallback((value) => {
        if(intervalId.current) {
            clearInterval(intervalId.current);
            intervalId.current = null;
        }
        if (!value) { return }
        intervalId.current = setInterval(() => { 
            if(!AM_socketId()) { webSocketConnect() } 
            if(updating.current || AM_socketId()) { return }
            checkForScriptUpdates();
        }, 60000);
    }, [checkForScriptUpdates, webSocketConnect]);

    useEffect(() => {
        if (scriptIds.current) { setClearInterval(true) };
        return () => setClearInterval(false);       
    }, [setClearInterval]);

    useEffect(() => {
        return () => {
            if(socket.current?.readyState === WebSocket.OPEN) { socket.current.close() }
        }
    },[])

    return (
        <PronunciationContext.Provider value={{ scriptIds, glossary, glossaryMapping, setGlossaryData, addPronunciationsToDataSource, targetColumn, liveTask, taskId,
                                                searchPronunciationTerms, fetchPronunciations, setClearInterval, updateAlert, highlightChanges,
                                                getPronunciationsTable, reloadPronunciations, scriptPersistence, dataSource, refreshColumn, unfilteredDataSource,
                                                qaStatusTags, undoState, multiSelection, playManual, actorsWindow, actorsWindowCheck, setPronGridDatasource,
                                                editPermissions, qaTagsGroups, userName, getNowDate, selChar, changeQaStatusOnComplete, projectTaskProgression2,
                                                extPlayerRef, extPlayerRefCheck, extVPlayerRef, activeLoopFromAudioModal, extVPlayerRefCheck, previousFiletype, tcColors,
                                                rolesData, extraColumnsHeaders, forceUnlock, tasksData, sidePanelsColumns, sharedLinesOptions, audioMateRoles, userPermission,
                                                breadcrumb, checkEnabled, externalUser, qaTags, containsContextLines, activeroles, userRoles, sharedLinesExist, refreshActorScript,
                                                checkForScriptUpdates, lastUpdate, dataSourceIndex, dataTargetIndex, refreshRow, refreshAll, localTargetUpdates, localSourceUpdates, 
                                                updating, localSharedUpdates, addLocalChanges, progBar, progBarUpdate, updateProgressBar, updateSidePanel, selectedId, sourceAudioBase,
                                                refreshPMUpdates, updateCounter, qaStatusData, lineCompletedCheck, scriptSettings, webSocketConnect, setLastUpdate, readOnly, checkDeactivated,
                                                targetFiletypes, setBugFixingText
                                                }}>
        {children}
        </PronunciationContext.Provider>
  );

};

export { PronunciationContext, PronunciationsContextProvider };