import React, { createContext, useState, useRef } from 'react';
const ActorScriptContext = createContext();

const ActorScriptContextProvider = ({ children }) => {
    
    const actorScriptDataSource = useRef([]);
    const actorScriptRef = useRef();
    const externalWindowRef = useRef();
    const [previousLines, setPreviousLines] = useState(1);
    const tcColors = useRef([]);
    const selChar = useRef(null);

  return (
    <ActorScriptContext.Provider value={{ actorScriptRef, actorScriptDataSource, externalWindowRef, previousLines, setPreviousLines, tcColors, selChar }}>
      {children}
    </ActorScriptContext.Provider>
  );

};

export { ActorScriptContext, ActorScriptContextProvider };