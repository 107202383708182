// InfoContext.js

import React, { createContext, useState } from 'react';

const InfoContext = createContext();

const InfoProvider = ({ children }) => {
  const [info, setInfo] = useState({
      'download': {status: 'none'},
      'upload': {status: 'none'},
      'scriptsubtitles': {
        status: 'none',
        address: ''
      },
    });

  const setInfoData = (data) => {
    setInfo(data);
  };


  return (
    <InfoContext.Provider value={{ info, setInfoData }}>
      {children}
    </InfoContext.Provider>
  );
};

export { InfoContext, InfoProvider };

/* instruction to use context 


//////////////////// for download ///////////////////////////

//  1. import the script. Located in ...
//    import  {InfoContext}  from './AM_BackGroundOP';
//  2. import the context in the component 
//    const { info, setInfoData } = useContext(InfoContext);
//  3. key of array for download

    [
      {
          to: "12345.wav", from: "71/source/13384.wav"
      },
      {
          to: "12346.wav", from: "71/source/13388.wav"
      }
    ]


//  4. start the download

//  multiple file (send just one object in the array) 

    setInfoData(previous => ({
        ...previous,
        download: {
            status: 'request',
            outputFilename: 'zipname.zip', 
            fileList: arrayOfFiles
        }
    }))

//  single file 

    setInfoData(previous => ({
        ...previous,
        download: {
            status: 'request',
            outputFilename: 'audiofile.wav', // or nothing for tak the original name  
            fileList: arrayOfFiles // just one object in the array
        }
    }))


*/